<template>
  <h1>规则管理</h1>
</template>


<script lang="js">
  import Vue from 'vue';
  import * as api from '@/api';
  import extend from "@/extensions/delRows.js"
  export default {
      name: 'Rules',
      components: { 
        
      },
      async created() {
        this.$store.commit('breadcrumbList', [
          {
            name: '规则管理',
            path: 'rules',
          },
        ]);
      },
  }
  </script>

<style lang='less'>

</style>